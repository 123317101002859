<template>
  <div>
    <b-modal
      id="modal-edit-ms-supplier"
      size="lg"
      centered
      :title="'Update Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="tutupModal"
      v-if="is_data"
    >
      <b-form-group label-cols-md="3">
        <template v-slot:label> Nama Supplier <span class="text-danger">*</span> </template>
        <b-form-input
          :state="checkIfValid('nama_supplier')"
          type="text"
          v-model="$v.is_data.nama_supplier.$model"
        ></b-form-input>
      </b-form-group>

      <b-form-group label-cols-md="3">
        <template v-slot:label> Tipe Gudang <span class="text-danger">*</span> </template>
        <b-form-input
          :state="checkIfValid('alamat_supplier')"
          type="text"
          v-model="$v.is_data.alamat_supplier.$model"
        ></b-form-input>
      </b-form-group>
      
      <b-form-group label-cols-md="3">
        <template v-slot:label> Contact Person <span class="text-danger">*</span> </template>
        <b-form-input
          :state="checkIfValid('cp_supplier')"
          type="text"
          v-model="$v.is_data.cp_supplier.$model"
        ></b-form-input>
      </b-form-group>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit-ms-supplier')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "modalEdit",
  props: ["fileName", "data_edit"],
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
      file_name: this.fileName,
      is_gudang_utama: true
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  watch: {
    data_edit(newVal) {
      this.is_data = newVal;
    }
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_supplier: { required },
      alamat_supplier: { required },
      cp_supplier: { required },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      console.log(vm.is_data);
      vm.is_data.id = vm.is_data[`${vm.file_name}_id`]
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post(`/${vm.file_name}/update`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-ms-supplier");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_supplier: null,
        alamat_supplier: null,
        cp_supplier: null,
      };
    },
  },
};
</script>
